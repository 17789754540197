/* ion-content{
  --ion-background-color:#111D12;
} */

/*
THeming
https://ionicframework.com/docs/theming/advanced
*/

:root {
  --text-tv-background: rgb(17, 30, 63);
  --text-tv-color: #eee;
  --text-tv-color-rgb: 238, 238, 238;
  --text-tv-font-family: "Ubuntu Mono", Courier, monospace;
  --text-tv-font-size: 4.9vw;
  --text-tv-color-white: var(--text-tv-color);
  --text-tv-color-yellow: #e2e200;
  --text-tv-color-cyan: #00c8ee;

  --ion-background-color: var(--text-tv-background);
  --ion-text-color: var(--text-tv-color);
  --ion-text-color-rgb: var(--text-tv-color-rgb);
  --ion-font-family: var(--text-tv-font-family);

  /* --ion-text-color: #fff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-font-family: "Ubuntu Mono"; */

  /* --ion-background-color: var(--text-tv-background); */

  --ion-color-primary: #0066b5;
  --ion-color-primary-rgb: 0, 102, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #3b89c5;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /* --ion-color-dark: #222428; */
  --ion-color-dark: var(--text-tv-background);
  --ion-color-dark-rgb: 17, 30, 63;
  --ion-color-dark-contrast: #eee;
  --ion-color-dark-contrast-rgb: 238, 238, 238;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* ion-segment {
  --margin-start: 5px;
  --padding-start: 5px;
}

ion-segment-button {
  --margin-start: 5px;
  --padding-start: 5px;
} */

