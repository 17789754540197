/* @font-face {
  font-family: "iA Writer Mono";
  src: local("iA Writer Mono"),
    url("./fonts/iA Writer Mono/Webfonts/iAWriterMonoS-Regular.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Ubuntu Mono";
  src: local("Ubuntu Mono"),
    url("./fonts/ubuntu-font-family-0.83/UbuntuMono-R.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu Mono";
  src: local("Ubuntu Mono"),
    url("./fonts/ubuntu-font-family-0.83/UbuntuMono-B.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.TextTVPage,
.TextTVSkeletonPage {
  font-family: "Ubuntu Mono", monospace;
  background: var(--text-tv-background);
  color: #ddd;
  font-size: 4.9vw;
  text-align: center;
  line-height: 1;
  padding: 10px 10px 10px 0;
}

.TextTVPage--skeleton .TextTVPage__inner {
  position: relative;
}

/* @media only screen and (min-width: 250px) {
  .TextTVPage {
    font-size: 10px;
  }
}

@media only screen and (min-width: 320px) {
  .TextTVPage {
    font-size: 14px;
  }
}

@media only screen and (min-width: 375px) {
  .TextTVPage {
    font-size: 16px;
  }
}

@media only screen and (min-width: 414px) {
  .TextTVPage {
    font-size: 18px;
  }
} */
/* Lite mindre storlek på desktop så vi får plats med scrollbar */
/* .plt-desktop .TextTVPage {
  font-size: 4.7vw;
} */

.TextTVSkeletonPage {
  white-space: pre;
}

ion-skeleton-text {
  /* outline: 1px solid red; */
}

/* .TextTVPage__wrap {
}

.TextTVPage__inner {
} */

.TextTVPage pre,
.TextTVPage ul,
.TextTVPage dl,
.TextTVPage p,
.TextTVPage input,
.TextTVPage button,
.TextTVPage body,
.TextTVPage pre,
.TextTVPage span,
.TextTVPage body,
.TextTVPage pre,
.TextTVPage h1,
.TextTVPage h2 {
  font-weight: normal;
  font-family: inherit;
  font-size: inherit;
  display: inline;
  margin: 0;
  padding: 0;
  line-height: 100%;
}

.TextTVPage ul {
  list-style-type: none;
}

.TextTVPage a {
  color: rgb(221, 221, 221);
}
.TextTVPage .root {
  white-space: pre;
}
.TextTVPage .root .toprow {
  color: #ddd;
  line-height: 1;
}
.TextTVPage .inpage-pages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.TextTVPage h1,
.TextTVPage h2 {
  font-size: 100%;
  display: inline;
}
.TextTVPage .B {
  color: #336;
}
.TextTVPage .C {
  color: #066;
}
.TextTVPage .W {
  color: #333;
}
.TextTVPage .Y {
  color: #660;
}
.TextTVPage .DH {
  font-weight: normal;
  font-family: inherit;
  font-weight: 700;
}
/* .TextTVPage .bgB,
.TextTVPage .bgB a span {
} */
.TextTVPage .bgW,
.TextTVPage .bgW a span {
  background-color: orange;
}
.TextTVPage .bgG,
.TextTVPage .bgG a span {
  background-color: rgba(0, 255, 0, 0.5);
}
.TextTVPage .bgR,
.TextTVPage .bgR a span {
  background-color: rgba(255, 0, 0, 0.25);
}
.TextTVPage .bgC,
.TextTVPage .bgC a span {
  background-color: rgb(0, 200, 238);
}
.TextTVPage .R,
.TextTVPage .R a,
.TextTVPage .R a:visited,
.TextTVPage .R a:visited:hover {
  color: #f00;
}
.TextTVPage .B,
.TextTVPage .B a {
  color: #12c;
}
.TextTVPage .W,
.TextTVPage .W a {
  color: #ddd;
}
.TextTVPage .Y,
.TextTVPage .Y a {
  color: #e2e200;
}
.TextTVPage .C,
.TextTVPage .C a {
  color: #00c8ee;
}
.TextTVPage .bgB,
.TextTVPage .bgB a {
  background-color: #00f;
  color: #ddd;
}
/* .TextTVPage .DH {
} */
.TextTVPage .G,
.TextTVPage .G a {
  color: #0f0;
}
.TextTVPage .bgY,
.TextTVPage .bgY a {
  background-color: #e2e200;
  color: #12c;
}

/**
 * Bug fix for ion list on page Sidor: white default bg bleeds through at the top.
 */
ion-list[color="dark"] {
  background: var(--ion-color-dark);
}

.MomentTime {
  display: inline-block;
}

.MomentTime::first-letter {
  text-transform: uppercase;
}

.ListHeadline {
  color: var(--text-tv-color-yellow) !important;
  font-size: var(--text-tv-font-size) !important;
  font-weight: bold !important;
}
.ListHeadlineSidor {
  color: var(--text-tv-color-white) !important;
  font-size: var(--text-tv-font-size) !important;
  /* font-weight: bold !important; */
}

.texttv-logo {
  display: inline-block;
  width: 28px;
  vertical-align: middle;
  position: relative;
  margin-right: 10px;
}

@media only screen and (min-width: 540px) {
  .TextTVPage,
  .TextTVSkeletonPage,
  .ListHeadline,
  .ListHeadlineSidor {
    font-size: 22px !important;
  }
}
